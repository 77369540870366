<template>
  <div
    class="SiteNotice transition-all duration-500 bg-darkest text-lightest w-full type-xs relative z-header"
    :class="{
      'h-32': shouldShowNotice,
      'h-0': !shouldShowNotice,
    }"
  >
    <Teleport to="body">
      <transition name="fromRight">
        <GenericSideMenu
          v-if="showSiteNotice"
          position="right"
          @close="uiStore.closeAllModal()"
        >
          <div>
            <div class="wysiwyg" v-html="readMoreContent" />
          </div>
        </GenericSideMenu>
      </transition>
    </Teleport>
    <div v-if="settings.displaySiteMessage && !hideSiteMessage" class="h-full">
      <div
        v-if="siteMessageText !== false"
        class="px-16 flex items-center justify-center h-full py-[10px]"
        :class="`color-${settings.siteMessageBackgroundColor}`"
      >
        <div v-if="settings.displayDismissSiteMessage" class="hidden desk:block basis-[14px] shrink-0" />
        <div class="basis-full relative desk:flex justify-center">
          <div
            ref="wysiwygContent"
            class="wysiwyg truncate-p"
            v-html="siteMessageText"
          />
          <button
            v-if="settings.displayReadMoreContent"
            class="mobOnly:absolute right-0 top-0 text-10 desk:text-12 whitespace-nowrap leading-single px-8 desk:pl-8 underline"
            :class="`color-${settings.siteMessageBackgroundColor}`"
            @click="openReadMoreModal"
          >
            {{ $t('productPage.truncateTextCtaClosed') }}
          </button>
        </div>
        <div v-if="settings.displayDismissSiteMessage" class="basis-[16px] shrink-0 desk:cursor-pointer">
          <img
            :src="closeButtonWhite ? '/icons/close-inv.svg' : '/icons/close.svg'"
            class="h-[16px] w-[16px]"
            @click="closeSiteMessage"
          >
        </div>
      </div>
    </div>
    <div v-else-if="hasNotification" class="relative flex items-center justify-center h-full w-full type-xs text-darkest bg-brandPrimary">
      <div class="flex gap-4">
        {{ $t('mypage.notifications.orderProposal.text') }}!
        <nuxt-link class="underline cursor-pointer" :to="accountUrl('orderProposals')">
          {{ $t('mypage.notifications.orderProposal.btn') }}
        </nuxt-link>
      </div>
      <div class="absolute top-8 right-8 desk:right-24">
        <button
          @click="userStore.markAllNotificationAsSeen('orderProposal')"
        >
          <img
            src="/icons/close.svg"
            class="h-16 w-16 inline-block cursor-pointer"
            alt="Close"
          >
        </button>
      </div>
    </div>
    <div
      v-else-if="settings.displaySiteMarquee && settings.siteMarqueeItemList.length > 0"
      class="h-full flex items-center"
      :class="`color-${settings.siteMarqueeBackgroundColor}`"
    >
      <client-only>
        <Vue3Marquee
          :clone="true"
          :duration="duration"
          class="w-full select-none"
        >
          <div class="my-2 flex items-center">
            <div
              v-for="item in settings.siteMarqueeItemList"
              :key="item._uid"
              class="whitespace-nowrap transition mr-16 desk:mr-24 type-headline-xxs"
            >
              <div class="fixBaseline">{{ item.text }}</div>
            </div>
          </div>
        </Vue3Marquee>
      </client-only>
    </div>

    <div
      v-else-if="hasSiteUsps"
      :style="{backgroundColor : settings.siteUspBackgroundColor?.color}"
      class="flex justify-center items-center h-full w-full"
    >
      <div
        class="relative flex items-center desk:hidden w-full h-full"
        :class="settings.siteUspTextColor ? 'text-lightest' : 'text-darkest'"
      >
        <div
          v-for="(usp, index) in settings.siteUspList"
          :key="usp._uid"
          class="absolute w-full flex flex-row items-center justify-center gap-8 usp-text"
          :class="{ active: index === currentUspIndex }"
        >
          <img
            :src="settings.siteUspTextColor ? '/icons/check-circle-inv.svg' : '/icons/check-circle.svg'"
            class="w-16 h-16 text-14"
            alt="✔"
          >
          <div>{{ usp.text }}</div>
        </div>
      </div>
      <div
        class="hidden desk:flex items-center gap-16"
        :class="settings.siteUspTextColor ? 'text-lightest' : 'text-darkest'"
      >
        <div
          v-for="usp in settings.siteUspList"
          :key="usp._uid"
          class="shrink-0 flex gap-8"
        >
          <img
            :src="settings.siteUspTextColor ? '/icons/check-circle-inv.svg' : '/icons/check-circle.svg'"
            class="w-16 h-16 text-14"
            alt="✔"
          >
          <div>{{ usp.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { StoryBlokSiteNoticeData } from '~/constants/types/storyblok';
import { useGlobalContentStore } from '~/store/globalContent';
import { ISbRichtext } from 'storyblok-js-client';
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { storeToRefs } from 'pinia';
import { Vue3Marquee } from 'vue3-marquee';
import useLinkReplacer from '~/composeables/useLinkReplacer';

const uiStore = useUiStore();
const userStore = useUserStore();
const { showSiteNotice } = storeToRefs(uiStore);
const { accountUrl } = useLinkReplacer();

const props = defineProps<{
  settings: StoryBlokSiteNoticeData;
}>();

const globalContentStore = useGlobalContentStore();
const { hideSiteMessage } = storeToRefs(globalContentStore);

userStore.loadNotifications();

const hasSiteUsps = computed(() => ((props.settings.siteUspList || []).length > 0));
const hasNotification = computed(() => userStore.loggedIn && !userStore.isSalesRepUser && userStore.getUnseenNotifications('orderProposal').length > 0);

const shouldShowNotice = computed(() => {
  return !hideSiteMessage.value || hasSiteUsps || hasNotification;
});
const siteMessageText = !hideSiteMessage.value ? computed(() => renderRichText(props.settings.siteMessage as ISbRichtext)) : false;
const readMoreContent = computed(() => renderRichText(props.settings.readMoreContent as ISbRichtext));
const wysiwygContent = ref<HTMLElement | null>(null);

const marqueeItem = ref<HTMLElement[] | null>(null);

watch(marqueeItem, () => {
  marqueeItem.value && marqueeItem.value.forEach((item, i) => {
    if (i % 2 === 0) item.classList.value = item.classList.value + ' font-medium';
  });
});

const closeSiteMessage = () => {
  globalContentStore.setHideSiteMessage(true);
};

const openReadMoreModal = () => {
  uiStore.setShowSiteNotice(true);
};

const closeButtonWhite = computed(()=> {
  return props.settings.siteMessageBackgroundColor === 'criticalDark' ||
    props.settings.siteMessageBackgroundColor === 'darker' ||
    props.settings.siteMessageBackgroundColor === 'darkest';

});

const currentUspIndex = ref(0);
const totalUsps = props.settings.siteUspList.length;

onMounted(() => {
  setInterval(() => {
    currentUspIndex.value = (currentUspIndex.value + 1) % totalUsps;
  }, 3000); // Change USP every 3 seconds (adjust as needed)
});

const duration = ref(props.settings.siteMarqueeSpeed ? parseInt(props.settings.siteMarqueeSpeed?.toString(), 10) : 10); // Default to 10 if not present

</script>

<style lang="postcss">
.truncate-p > p {
  @apply truncate text-10 desk:text-12 leading-single;
}
.usp-text {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.usp-text.active {
  opacity: 1;
}
</style>
